import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, createVNode as _createVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a0c0593e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "node-label"
}
const _hoisted_2 = {
  key: 1,
  class: "node-placeholder text-muted"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "node",
    tabindex: "0",
    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
  }, [
    ($setup.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.__($setup.label)), 1 /* TEXT */))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.__("No Label")), 1 /* TEXT */)),
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['top', 'right', 'bottom', 'left'], (handle) => {
      return _createVNode($setup["Handle"], {
        class: "handle",
        style: _normalizeStyle({ [handle]: '-12px' }),
        type: "source",
        position: handle,
        id: handle,
        isValidConnection: $setup.isValidConnection
      }, null, 8 /* PROPS */, ["style", "position", "id"])
    }), 64 /* STABLE_FRAGMENT */))
  ]))
}