import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fb74ba4f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "properties" }
const _hoisted_3 = { class: "control-data" }
const _hoisted_4 = { key: 0 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.__($setup.title)), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        ($setup.doc)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.properties, (df) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "field",
                  key: df.name
                }, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(df.fieldtype.replaceAll(' ', '') + 'Control'), {
                    df: df,
                    value: $setup.doc[df.fieldname],
                    modelValue: $setup.doc[df.fieldname],
                    "onUpdate:modelValue": $event => (($setup.doc[df.fieldname]) = $event),
                    "data-fieldname": df.fieldname,
                    "data-fieldtype": df.fieldtype
                  }, null, 8 /* PROPS */, ["df", "value", "modelValue", "onUpdate:modelValue", "data-fieldname", "data-fieldtype"]))
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
          : _createCommentVNode("v-if", true)
      ])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}