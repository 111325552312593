import { createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "control editable" }
const _hoisted_2 = { class: "field-controls" }
const _hoisted_3 = { class: "btn btn-sm btn-default" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" label "),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "label"),
      _renderSlot(_ctx.$slots, "actions")
    ]),
    _createCommentVNode(" attach button "),
    _createElementVNode("button", _hoisted_3, _toDisplayString(_ctx.__("Attach")), 1 /* TEXT */),
    _createCommentVNode(" description "),
    ($props.df.description)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "mt-2 description",
          innerHTML: $props.df.description
        }, null, 8 /* PROPS */, _hoisted_4))
      : _createCommentVNode("v-if", true)
  ]))
}