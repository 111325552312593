import { createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c4748aba"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "main",
  ref: "main"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "empty-state" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "sidebar-container",
      onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
    }, [
      _createVNode($setup["Sidebar"])
    ]),
    _createElementVNode("div", {
      class: "workflow-container",
      onDrop: $setup.onDrop,
      onClick: _withModifiers($setup.loose_focus, ["stop"])
    }, [
      _createVNode($setup["VueFlow"], {
        modelValue: $setup.store.workflow.elements,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.store.workflow.elements) = $event)),
        "connection-mode": "loose",
        onDragover: $setup.onDragOver,
        "delete-key-code": null
      }, {
        "node-state": _withCtx((node) => [
          _createVNode($setup["StateNode"], { node: node }, null, 8 /* PROPS */, ["node"])
        ]),
        "node-action": _withCtx((node) => [
          _createVNode($setup["ActionNode"], { node: node }, null, 8 /* PROPS */, ["node"])
        ]),
        "edge-transition": _withCtx((props) => [
          _createVNode($setup["TransitionEdge"], _normalizeProps(_guardReactiveProps(props)), null, 16 /* FULL_PROPS */)
        ]),
        "connection-line": _withCtx((props) => [
          _createVNode($setup["ConnectionLine"], _normalizeProps(_guardReactiveProps(props)), null, 16 /* FULL_PROPS */)
        ]),
        default: _withCtx(() => [
          _createVNode($setup["Background"], {
            "pattern-color": "#aaa",
            gap: "10"
          }),
          _createVNode($setup["Panel"], {
            position: $setup.PanelPosition.TopRight
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", {
                  class: "btn btn-md drag-handle",
                  draggable: true,
                  onDragstart: $setup.onDragStart
                }, _toDisplayString(_ctx.__("Drag to add state")), 33 /* TEXT, NEED_HYDRATION */)
              ])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["position"]),
          _createVNode($setup["Panel"], {
            position: $setup.PanelPosition.BottomLeft
          }, {
            default: _withCtx(() => [
              _createElementVNode("button", {
                class: "btn btn-sm btn-default mr-2",
                onClick: _cache[1] || (_cache[1] = (...args) => ($setup.zoomIn && $setup.zoomIn(...args)))
              }, "+"),
              _createElementVNode("button", {
                class: "btn btn-sm btn-default mr-2",
                onClick: _cache[2] || (_cache[2] = (...args) => ($setup.zoomOut && $setup.zoomOut(...args)))
              }, "-"),
              _createElementVNode("button", {
                class: "btn btn-sm btn-default",
                onClick: _cache[3] || (_cache[3] = $event => ($setup.fitView()))
              }, _toDisplayString(_ctx.__("Fit")), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["position"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"])
    ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_2)
  ], 512 /* NEED_PATCH */))
}