import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "control editable" }
const _hoisted_2 = { class: "field-controls" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "label"),
      _renderSlot(_ctx.$slots, "actions")
    ]),
    _createElementVNode("div", {
      class: "missing-image",
      innerHTML: _ctx.frappe.utils.icon('restriction', 'md')
    }, null, 8 /* PROPS */, _hoisted_3),
    ($props.df.description)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "mt-2 description",
          innerHTML: $props.df.description
        }, null, 8 /* PROPS */, _hoisted_4))
      : _createCommentVNode("v-if", true)
  ]))
}