import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "field-controls" }
const _hoisted_2 = { ref: "code" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 1,
  class: "control",
  ref: "code"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.slots.label)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["control", { editable: $setup.slots.label }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "label"),
          _renderSlot(_ctx.$slots, "actions")
        ]),
        _createElementVNode("div", _hoisted_2, null, 512 /* NEED_PATCH */),
        ($props.df.description)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "mt-2 description",
              innerHTML: $props.df.description
            }, null, 8 /* PROPS */, _hoisted_3))
          : _createCommentVNode("v-if", true)
      ], 2 /* CLASS */))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, null, 512 /* NEED_PATCH */))
}